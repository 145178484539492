<template>
  <div>
    <div class="f24 fb tc" style="padding: 70px 100px 50px 100px;">
        中后台系统案例
        <div class="flex mt20" style="flex-wrap: wrap;">
          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'A')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img2.png">
              <div class="flex flexR itemC rowC mb10">
                <img height="20" class="shadow3" src="../assets/logo2.png">
                <div class="pl10 f14 textWhite">
                  汇流用户聚合系统
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'B')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img3.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" src="../assets/logo3.png">
                <div class="pl10 f14 textWhite">
                  合思企业管理平台
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'C')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img1.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" class="shadow3" src="../assets/logo1.png">
                <div class="pl10 f14 textWhite">
                  化工园区智能管理平台
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'D')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img4.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" src="../assets/logo4.png">
                <div class="pl10 f14 textWhite">
                  鲁班大牛
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="f24 fb tc bgGray" style="padding: 70px 100px 50px 100px;">
        小程序/App案例
        <div class="flex mt20">
          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'H')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img20.png">
              <div class="flex flexR itemC rowC mb10">
                <img height="15" class="shadow3" src="../assets/logo20.png">
                <div class="pl10 f14 textWhite">
                  合思商城
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'I')">
            <div class="br10 shadow4 bgBlack" style="">
                <img class="mb10 br2" width="100%" src="../assets/img21.png">
                <div class="flex flexR itemC rowC mb10">
                  <img height="20" class="shadow3" src="../assets/logo21.png">
                  <div class="pl10 f14 textWhite">
                    汇流直推
                  </div>
                </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'J')">
            <div class="br10 shadow4 bgBlack" style="">
              <div style="overflow: hidden; height: 450px;" class="mb10 br2">
                <img width="100%" src="../assets/img22.png">
              </div>
              <div class="flex flexR itemC rowC mb10">
                <img height="15" class="shadow3" src="../assets/logo22.png">
                <div class="pl10 f14 textWhite">
                  三元及递
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'K')">
            <div class="br10 shadow4 bgBlack" style="">
                <img class="mb10 br2" width="100%" src="../assets/img23.png">
                <div class="flex flexR itemC rowC mb10">
                  <img height="20" class="shadow3" src="../assets/logo23.png">
                  <div class="pl10 f14 textWhite">
                    闪电超市
                  </div>
                </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'L')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img24.png">
              <div class="flex flexR itemC rowC mb10">
                <img height="15" class="shadow3" src="../assets/logo24.png">
                <div class="pl10 f14 textWhite">
                  美菜商城
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="f24 fb tc" style="padding: 70px 100px 50px 100px;">
        BI报表/大屏数据展示案例
        <div class="flex mt20" style="flex-wrap: wrap;">
          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'O')">
            <div class="br10 shadow4 bgBlack" style="">
              <video width="100%" class="mb10 br2" autoplay="autoplay" loop="loop" muted="muted">
                <source src="../assets/img51.mp4" type="video/mp4">
              </video>
              <div class="flex flexR itemC rowC mb10">
                <div class="pl10 f14 textWhite">
                  启迪园区概况
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'P')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img50.png">
              <div class="flex flexR itemC rowC mb10">
                <!-- <img height="15" class="shadow3" src="../assets/logo50.png"> -->
                <div class="pl10 f14 textWhite">
                  5G数字化生活管控平台
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'Q')">
            <div class="br10 shadow4 bgBlack" style="">
              <video width="100%" class="mb10 br2" autoplay="autoplay" loop="loop" muted="muted">
                <source src="../assets/img52.mp4" type="video/mp4">
              </video>
              <div class="flex flexR itemC rowC mb10">
                <div class="pl10 f14 textWhite">
                  财务科数据展示
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex mt20" style="flex-wrap: wrap;">
          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'R')">
            <div class="br10 shadow4 bgBlack" style="">
              <video width="100%" class="mb10 br2" autoplay="autoplay" loop="loop" muted="muted">
                <source src="../assets/img53.mp4" type="video/mp4">
              </video>
              <div class="flex flexR itemC rowC mb10">
                <div class="pl10 f14 textWhite">
                  新农都数据监控分析
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'S')">
            <div class="br10 shadow4 bgBlack" style="">
              <video width="100%" class="mb10 br2" autoplay="autoplay" loop="loop" muted="muted">
                <source src="../assets/video1.mp4" type="video/mp4">
              </video>
              <div class="flex flexR itemC rowC mb10">
                <div class="pl10 f14 textWhite">
                  工程建设作战指挥室
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="f24 fb tc bgGray" style="padding: 70px 100px 50px 100px;">
        前台官网、电商案例
        <div class="flex mt20" style="flex-wrap: wrap;">
          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer" @click="getDetail($event, 'X')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img10.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" class="shadow3" src="../assets/logo10.png">
                <div class="pl10 f14 textWhite">
                  合思商旅
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer"  @click="getDetail($event, 'Y')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img11.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" class="shadow3" src="../assets/logo11.png">
                <div class="pl10 f14 textWhite">
                  gotin有大
                </div>
              </div>
            </div>
          </div>

          <div class="flex1 m20 tc flex flexC itemC m-w200 pointer"  @click="getDetail($event, 'Z')">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img12.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" class="shadow3" src="../assets/logo12.png">
                <div class="pl10 f14 textWhite">
                  美菜官网
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="flex1 m20 tc flex flexC itemC m-w200 pointer">
            <div class="br10 shadow4 bgBlack" style="">
              <img class="mb10 br2" width="100%" src="../assets/img10.png">
              <div class="flex flexR itemC rowC mb10">
                <img width="20" class="shadow3" src="../assets/logo10.png">
                <div class="pl10 f14 textWhite">
                  合思商旅
                </div>
              </div>
            </div>
          </div> -->
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Case',
    methods: {
      getDetail(e, page) {
        this.$router.push({ name: `Deatail${page}` });
      }
    }
  }
</script>
